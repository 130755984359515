import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object, number } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
  SOCIAL_SHARE_MODAL,
  REPORT_USER_MODAL,
  PUBLISHED,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { formatMoney, convertMoneyToNumber } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureCurrentUser,
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  OrderPanel,
  Modal,
  Button,
  ListingCard,
  AvatarLarge,
  SocialShare,
  PaginationLinks,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import {
  sendEnquiry,
  fetchTransactionLineItems,
  setInitialValues,
  sendReportUserEmail,
  sendOffer,
  cartItemNotification,
  cancelCartItemNotification,
} from './ListingPage.duck';
import SectionAvatar from './SectionAvatar';
import ActionBarMaybe from './ActionBarMaybe';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import ReportUserForm from './ReportUserForm/ReportUserForm';
import classNames from 'classnames';
import { setActiveListing } from '../SearchPage/SearchPage.duck';
import { getUserBagData, getUserDetails } from '../../util/dataExtractor';
import MakeAnOfferForm from './MakeAnOfferForm/MakeAnOfferForm';
import { withViewport } from '../../util/contextHelpers';
import moment from 'moment';
import TwilioVerificationForm from '../EditListingFinalPage/TwilioVerificationForm/TwilioVerificationForm';
import {
  sendPhoneVerificationCode,
  verifiedCode,
} from '../EditListingFinalPage/EditListingFinalPage.duck';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="46"
        viewBox="0 0 26 46"
        fill="none"
      >
        <path
          d="M21.0232 22.7045L1.67351 3.42001C1.48443 3.2328 1.33502 3.00972 1.23413 2.76399C1.13324 2.51827 1.08292 2.25489 1.08615 1.98946C1.08938 1.72402 1.1461 1.46193 1.25295 1.21872C1.3598 0.975494 1.5146 0.756088 1.70818 0.573494C2.10414 0.19939 2.63107 -0.00629437 3.17699 0.000148634C3.7229 0.00659163 4.24478 0.224651 4.63172 0.607994L25.4114 21.3186C25.5994 21.5044 25.7482 21.7257 25.8493 21.9694C25.9504 22.2132 26.0016 22.4746 26 22.7383C25.9984 23.002 25.9439 23.2627 25.8399 23.5052C25.7359 23.7477 25.5843 23.9672 25.394 24.1507L3.52239 45.4134C3.13085 45.7897 2.60781 46 2.06351 46C1.51922 46 0.99618 45.7897 0.604634 45.4134C0.413318 45.2285 0.261228 45.0072 0.157371 44.7627C0.0535134 44.5183 -7.62527e-08 44.2555 -8.78549e-08 43.9901C-9.94571e-08 43.7247 0.0535134 43.462 0.157371 43.2175C0.261228 42.973 0.413318 42.7518 0.604634 42.5669L21.0232 22.7045Z"
          fill="#3065A1"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <svg
        style={{
          transform: 'rotate(178deg)',
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="46"
        viewBox="0 0 26 46"
        fill="none"
      >
        <path
          d="M21.0232 22.7045L1.67351 3.42001C1.48443 3.2328 1.33502 3.00972 1.23413 2.76399C1.13324 2.51827 1.08292 2.25489 1.08615 1.98946C1.08938 1.72402 1.1461 1.46193 1.25295 1.21872C1.3598 0.975494 1.5146 0.756088 1.70818 0.573494C2.10414 0.19939 2.63107 -0.00629437 3.17699 0.000148634C3.7229 0.00659163 4.24478 0.224651 4.63172 0.607994L25.4114 21.3186C25.5994 21.5044 25.7482 21.7257 25.8493 21.9694C25.9504 22.2132 26.0016 22.4746 26 22.7383C25.9984 23.002 25.9439 23.2627 25.8399 23.5052C25.7359 23.7477 25.5843 23.9672 25.394 24.1507L3.52239 45.4134C3.13085 45.7897 2.60781 46 2.06351 46C1.51922 46 0.99618 45.7897 0.604634 45.4134C0.413318 45.2285 0.261228 45.0072 0.157371 44.7627C0.0535134 44.5183 -7.62527e-08 44.2555 -8.78549e-08 43.9901C-9.94571e-08 43.7247 0.0535134 43.462 0.157371 43.2175C0.261228 42.973 0.413318 42.7518 0.604634 42.5669L21.0232 22.7045Z"
          fill="#3065A1"
        />
      </svg>
    </div>
  );
}

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      isModalOpen: null,
      isMakeAnOfferModal: false,
      codeError: false,
      isReponseCodeInProgress: false,
      verificationModal: false,
      bookmarks: getUserBagData(props.currentUser),
      addToRecentView: {
        object: props.currentUser?.attributes?.profile?.publicData?.recentViewData || {
          clothing: [],
          sneakers: [],
          streetwear: [],
          vintage: [],
        },
      },

    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onSubmitOffer = this.onSubmitOffer.bind(this);
    this.handleWishlist = this.handleWishlist.bind(this);
    this.addData = this.addData.bind(this);
  }

  addData = async (listingStore, listingId) => {
    const { onUpdateProfileData, currentUser } = this.props;
    const { recentlyViewedItems = [] } = !!currentUser?.id && currentUser.attributes.profile.publicData || {};
    const date = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    if (currentUser) {
      const existingIndex = recentlyViewedItems.findIndex(item => item.id === listingId);

      if (existingIndex !== -1) {
        // If the listingId exists in the array, update its date
        recentlyViewedItems[existingIndex].date = date;
      } else {
        // If the array length is already 3, remove the last item
        if (recentlyViewedItems.length >= 60) {
          recentlyViewedItems.pop();
        }
        // Add the new item to the beginning of the array
        recentlyViewedItems.unshift({ id: listingId, store: listingStore, date });
      }

      // Sort the array in descending order based on the date
      recentlyViewedItems.sort((a, b) => {
        const dateA = moment(a.date, 'DD-MM-yyyy hh:mm:ss');
        const dateB = moment(b.date, 'DD-MM-yyyy hh:mm:ss');
        return dateB.diff(dateA);
      });

      // Update the user's profile data
      onUpdateProfileData({ publicData: { recentlyViewedItems } });
    }
  };

  // You can use componentDidMount or componentDidUpdate for the effect
  componentDidMount() {
    const { params: rawParams, getListing, getOwnListing } = this.props;
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    // Assuming currentListing is defined and has the necessary properties
    if (currentListing && currentListing.id) {
      sessionStorage.removeItem('sessionSelectedServices');
      sessionStorage.removeItem('sessionRateData');
      // Add a condition to prevent an infinite loop
      this.addData(currentListing.attributes.publicData.listingStore, currentListing.id.uuid);
    }
  }

  handleWishlist = async (currentListing) => {

    const id = currentListing.id.uuid
    const {
      onUpdateProfileData,
      currentUser,
      isAuthenticated,
      onCartItemNotification,
      onCancelCartItemNotification,
    } = this.props;

    const authorAvailable = currentListing?.author;
    const bookmarks = getUserBagData(currentUser) || [];
    const index = bookmarks.findIndex(b => b.id === id);
    const batchId = bookmarks.find(b => b.id === id)?.batchId;

    try {
      if (!isAuthenticated && id) {
        let localBookmarks =
          typeof window !== 'undefined' &&
          JSON.parse(window.localStorage.getItem('localBookmarks')) || [];

        if (localBookmarks.includes(id)) {
          localBookmarks = localBookmarks.filter(b => b !== id);
        } else {
          localBookmarks.push(id);
        }

        const uniqueLocalBookmarks = Array.from(new Set(localBookmarks));
        window.localStorage.setItem('localBookmarks', JSON.stringify(uniqueLocalBookmarks));
        this.setState({ bookmarks: uniqueLocalBookmarks });
      }

      if (id) {
        if (index > -1 && currentUser?.id) {
          await onCancelCartItemNotification({ batch_id: batchId });
          bookmarks.splice(index, 1);
          const removedBookmarks = Array.from(new Set(bookmarks));
          this.setState({ bookmarks: removedBookmarks });

          const profile = {
            protectedData: {
              bookmarks: removedBookmarks,
            },
          };
          onUpdateProfileData(profile);
        } else {
          const titleSlug = createSlug(currentListing?.attributes?.title);
          const timestamp = Math.floor((Date.now() + 7 * 60 * 60 * 1000) / 1000); // Current time + 7 hours

          const emailData = {
            buyerFirstName: currentUser?.id && getUserDetails(currentUser).firstName,
            sellerUserName: authorAvailable?.id && getUserDetails(authorAvailable).userName,
            listingTitle: currentListing?.attributes?.title,
            listingPageUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/l/${titleSlug}/${currentListing?.id?.uuid}`,
            chekoutPageUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/bag`,
            userEmail: currentUser?.id && getUserDetails(currentUser).email,
            timestamp,
          };

          if (currentUser?.id) {
            const values = await onCartItemNotification(emailData);
            bookmarks.push({ id, batchId: values.batch_id });
            const addedBookmarks = Array.from(new Set(bookmarks));
            this.setState({ bookmarks: addedBookmarks });

            const profile = {
              protectedData: {
                bookmarks: addedBookmarks,
              },
            };
            onUpdateProfileData(profile);
          }
        }
      }
    } catch (error) {
    }
  };


  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      currentUser,
    } = this.props;
    const { isPhoneNumberVerified } =
      (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};
    if (!isPhoneNumberVerified) {
      this.setState({ verificationModal: true })
    }else{
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, quantity: quantityRaw, deliveryMethod, ...otherOrderData } = values;
    const bookingDatesMaybe = bookingDates
      ? {
        bookingDates: {
          bookingStart: bookingDates.startDate,
          bookingEnd: bookingDates.endDate,
        },
      }
      : {};

    const initialValues = {
      listing,
      orderData: {
        ...bookingDatesMaybe,
        quantity: 1,
        deliveryMethod: 'shipping',
        ...otherOrderData,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'PreCheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
    }
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }
  
  onSubmitOffer(values, currentListing) {
    const { history, params, onSendOffer, currentUser } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { listingPrice, offerPrice } = values;
    const { userName } = (!!currentUser?.id && currentUser?.attributes?.profile?.publicData) || {};
    const message = `${userName} sent you an offer of $${offerPrice?.amount / 100}.00 for the ${currentListing?.attributes?.title
      }. You have 24 hours to respond before this offer expires. `;
    const data = {
      listingPrice: listingPrice.amount / 100,
      offerPrice: offerPrice.amount / 100,
      listingTitle: createSlug(currentListing?.attributes?.title),
    };

    onSendOffer(listingId, message.trim(), data)
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      customConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onUpdateProfileData,
      onSendReportUserEmail,
      reportUserInProgress,
      pageListings,
      onActivateListing,
      callSetInitialValues,
      history,
      viewport,
      onSendVerificationCode,
      onVerifiedCode,
      isStripeConnected,
      validationInProgress,
      isPhonNumberExit,
      pagination,
    } = this.props;

    const paginationLinks =
      pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="ListingPage"
          pageSearchParams={parse(location.search)}
          pagination={pagination}
          pagePathParams={rawParams}
        />
      ) : null;
    const handleCodeVerified = async (code, phoneNumber) => {
      const result = await onVerifiedCode({ code, phoneNumber });
      if (result) {
        this.setState({
          isPopUpOpen: true,
          verificationModal: false,
          isReponseCodeInProgress: true,
        });
        setTimeout(() => {
          this.setState({
            isPopUpOpen: false,
            isReponseCodeInProgress: false,
            showPayoutDetails: true,
          });
        }, 5000);
      } else {
        this.setState({ codeError: true });
      }
    };

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'details';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;
    const { favouriteItems = [] } =
      (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};
    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );
    const localBookmarks =
      typeof window !== 'undefined' && JSON.parse(window.localStorage.getItem('localBookmarks'));

    const topbar = <TopbarContainer lb={localBookmarks} />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleOrderSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else if (!currentUser) {
        const state = {
          from: `${location.pathname}${location.search}${location.hash}`,
        };
        history.push(
          createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
          state
        );
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = listingImages(currentListing, `${config.listing.variantPrefix}-2x`).map(
      img => img.url
    );
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );
    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product
    const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
    const brand = currentListing?.attributes?.publicData?.brand;
    const brandMaybe = brand ? { brand: { '@type': 'Brand', name: brand } } : {};
    const schemaPriceNumber = intl.formatNumber(convertMoneyToNumber(price), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    const schemaAvailability =
      currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

    const authorLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#author' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const amenityOptions = findOptionsForSelectFilter('amenities', customConfig.filters);
    const categoryOptions = findOptionsForSelectFilter('category', customConfig.filters);
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

    const index = favouriteItems && favouriteItems.findIndex(e => e == currentListing.id.uuid);

    const handleFavouriteItems = () => {
      if (!currentUser) {
        const state = { from: `${location.pathname}${location.search}${location.hash}` };
        history.push(
          createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
          state
        );
      } else {
        if (index > -1) {
          favouriteItems.splice(index, 1);
          const addedfavouriteItems = Array.from(new Set(favouriteItems));
          const profile = {
            protectedData: {
              favouriteItems: addedfavouriteItems,
            },
          };
          onUpdateProfileData(profile);
        } else {
          favouriteItems.push(currentListing.id.uuid);
          const addedfavouriteItems = Array.from(new Set(favouriteItems));
          const profile = {
            protectedData: {
              favouriteItems: addedfavouriteItems,
            },
          };
          onUpdateProfileData(profile);
        }
      }
    };

    const handleSocialShare = () => {
      this.setState({
        isModalOpen: SOCIAL_SHARE_MODAL,
      });
    };

    let bookmarks = getUserBagData(currentUser) || [];
    const bookmarksArr = isAuthenticated ? bookmarks : localBookmarks;
    const isBookmarked = bookmarksArr?.some(
      bookmark => bookmark?.id === currentListing?.id?.uuid || bookmark === currentListing?.id?.uuid
    );

    const showEmailVerification = !!currentUser?.id &&currentUser.attributes.emailVerified;
    const addToCartAndRemoveFromCart = (
      <div className={css.addToCartBtn}>
        <div
          className={classNames(css.removeCard, {
            [css.filled]: isBookmarked,
            [css.removedCard]: !isBookmarked,
            [css.bookmark]: !isBookmarked,
          })}
          onClick={e => {
            this.handleWishlist(currentListing);
          }}
        >
          <p>
            <Button disabled={!showEmailVerification}>
              <FormattedMessage id={isBookmarked ? "ListingPage.removeToBag" : "ListingPage.addToBag"} />
            </Button>
          </p>
        </div>
      </div>
    );
    const makeAnOfferButton = (
      <div className={css.addToCartBtn}>
        <div
          className={classNames(
            bookmarksArr && bookmarksArr.findIndex(e => e.id == currentListing.id.uuid) > -1
              ? css.filled
              : css.removedCard,
            css.removeCard
          )}
          onClick={() => {
            const { isPhoneNumberVerified } =
              (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};
            if (!currentUser) {
              const state = {
                from: `${location.pathname}${location.search}${location.hash}`,
              };
              history.push(
                createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
                state
              );
            }
            else if(!isPhoneNumberVerified){
                this.setState({ verificationModal: true })
                }
            else {
              this.setState({
                isMakeAnOfferModal: true,
              });
            }
          }}
        >
          <p>
            <Button disabled={!showEmailVerification}>
              <FormattedMessage id="ListingPage.makeAnOffer" />
            </Button>
          </p>
        </div>
      </div>
    );

    const makeAnOfferModal = (
      <Modal
        id="makeAnOfferModal"
        containerClassName={css.test}
        isOpen={!!this.state.isMakeAnOfferModal}
        onClose={() => {
          this.setState({
            isMakeAnOfferModal: false,
          });
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={'close'}
        className={css.offerForm}
      >
        {/* <div>
          <h2><FormattedMessage id="ListingPage.makeAnOfferText" /></h2>
        </div> */}
        <MakeAnOfferForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          listing={currentListing}
          initialValues={{ listingPrice: price }}
          authorDisplayName={
            ensuredAuthor?.id && ensuredAuthor?.attributes?.profile?.publicData?.userName
          }
          sendEnquiryError={sendEnquiryError}
          onSubmit={values => this.onSubmitOffer(values, currentListing)}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>
    );
    const MobileWidth = 767;
    const isMobile = viewport.width > 0 && viewport.width <= MobileWidth;
    const displayName = authorDisplayName;
    const socialShareAndReportUserModal = (
      <Modal
        id="MissingInformationReminder"
        containerClassName={css.test}
        isOpen={!!this.state.isModalOpen}
        onClose={() => {
          this.setState({
            isModalOpen: null,
          });
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={'close'}
      >
        {this.state.isModalOpen === REPORT_USER_MODAL ? (
          <div>
            <ReportUserForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              listingTitle={title}
              authorDisplayName={authorDisplayName}
              sendEnquiryError={sendEnquiryError}
              onSubmit={values => {
                const userData = {
                  ...values,
                  reporterProfileUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/u/${currentUser.id.uuid}`,
                  reporterProfileEmail: !!currentUser.id && currentUser.attributes.email,
                  reportedProfileUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/u/${currentAuthor.id.uuid}`,
                  reportedProfileEmail:
                    !!currentAuthor.id && currentAuthor.attributes.profile.publicData.email,
                };
                onSendReportUserEmail(userData).then(() => {
                  this.setState({ isModalOpen: null });
                });
              }}
              inProgress={reportUserInProgress}
              onClose={() => {
                this.setState({
                  isModalOpen: null,
                });
              }}
            />
          </div>
        ) : (
          <div>
            <div className={css.logoImage}>
              <AvatarLarge className={css.avatar} user={currentListing.author} disableProfileLink />
            </div>

            <h1 className={css.authorName}>
              {displayName ? (
                <FormattedMessage id="ProfilePage.mobileHeading" values={{ name: displayName }} />
              ) : null}
            </h1>

            <SocialShare
              displayName={displayName}
              isListing={true}
              title={title}
              listingId={currentListing?.id?.uuid}
            />
          </div>
        )}
      </Modal>
    );
    const isMapVariant = true;
    const cardRenderSizes = isMapVariant => {
      if (isMapVariant) {
        // Panel width relative to the viewport
        const panelMediumWidth = 50;
        const panelLargeWidth = 62.5;
        return [
          '(max-width: 767px) 100vw',
          `(max-width: 1023px) ${panelMediumWidth}vw`,
          `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
          `${panelLargeWidth / 3}vw`,
        ].join(', ');
      } else {
        // Panel width relative to the viewport
        const panelMediumWidth = 50;
        const panelLargeWidth = 62.5;
        return [
          '(max-width: 549px) 100vw',
          '(max-width: 767px) 50vw',
          `(max-width: 1439px) 26vw`,
          `(max-width: 1920px) 18vw`,
          `14vw`,
        ].join(', ');
      }
    };

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    const storeType = currentListing.attributes.publicData?.listingStore;
    if (storeType == 'sneakers') {
      document.documentElement.style.setProperty('--marketplaceColor', '#D7282F');
      document.documentElement.style.setProperty('--marketplaceColorDark', '#D7282F');
      document.documentElement.style.setProperty('--matterColorNegative', '#F3E6E7');
      document.documentElement.style.setProperty('--marketplaceInitialDefaultColor', '#D7282F');
      document.documentElement.style.setProperty('--matterColorBright', '#F3E6E7');
    } else if (storeType == 'streetwear') {
      document.documentElement.style.setProperty('--marketplaceColor', '#E87200');
      document.documentElement.style.setProperty('--marketplaceColorDark', '#E87200');
      document.documentElement.style.setProperty('--matterColorNegative', '#FEF4EA');
      document.documentElement.style.setProperty('--marketplaceInitialDefaultColor', '#E87200');
      document.documentElement.style.setProperty('--matterColorBright', '#edf5fa');
    } else if (storeType == 'vintage') {
      document.documentElement.style.setProperty('--marketplaceColor', '#61A60E');
      document.documentElement.style.setProperty('--marketplaceColorDark', '#61A60E');
      document.documentElement.style.setProperty('--matterColorNegative', '#F2FAE9');
      document.documentElement.style.setProperty('--marketplaceInitialDefaultColor', '#61A60E');
      document.documentElement.style.setProperty('--matterColorBright', '#edf5fa');
    } else {
      document.documentElement.style.setProperty('--marketplaceColor', '#478cbc');
      document.documentElement.style.setProperty('--marketplaceColorDark', '#3065a1');
      document.documentElement.style.setProperty('--matterColorNegative', '#f8f7f7');
      document.documentElement.style.setProperty('--marketplaceInitialDefaultColor', '#238DC1');
    }
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        listStore={location.storeType}
        author={authorDisplayName}
        listingTitle={title}
        listingDescription={description}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          ...brandMaybe,
          offers: {
            '@type': 'Offer',
            url: productURL,
            priceCurrency: price.currency,
            price: schemaPriceNumber,
            availability: schemaAvailability,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.contentWrapperForProductLayout}>
              <div className={css.mainColumnForProductLayout}>
                {/* {currentListing.id ? (
                  <ActionBarMaybe
                    className={css.actionBarForProductLayout}
                    isOwnListing={isOwnListing}
                    listing={currentListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                  />
                ) : null} */}
                <SectionGallery
                  listing={currentListing}
                  handleFavouriteItems={handleFavouriteItems}
                  index={index}
                  handleSocialShare={handleSocialShare}
                />
                {socialShareAndReportUserModal}
                {makeAnOfferModal}
                {isMobile && (
                  <div className={css.orderColumnForProductLayout}>
                    <OrderPanel
                      className={css.productOrderPanel}
                      reviews={reviews}
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      unitType={unitType}
                      onSubmit={handleOrderSubmit}
                      title={bookingTitle}
                      author={ensuredAuthor}
                      onManageDisableScrolling={onManageDisableScrolling}
                      onContactUser={this.onContactUser}
                      timeSlots={timeSlots}
                      fetchTimeSlotsError={fetchTimeSlotsError}
                      onFetchTransactionLineItems={onFetchTransactionLineItems}
                      lineItems={lineItems}
                      isStripeConnected={isStripeConnected}
                      currentUser={currentUser}
                      makeAnOfferButton={makeAnOfferButton}
                      isReportModalOpen={() => {
                        if (!currentUser) {
                          const state = {
                            from: `${location.pathname}${location.search}${location.hash}`,
                          };
                          history.push(
                            createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
                            state
                          );
                        } else {
                          this.setState({
                            isModalOpen: REPORT_USER_MODAL,
                          });
                        }
                      }}
                      fetchLineItemsInProgress={fetchLineItemsInProgress}
                      fetchLineItemsError={fetchLineItemsError}
                      addToCartAndRemoveFromCart={addToCartAndRemoveFromCart}
                    />
                  </div>
                )}

                <SectionDescriptionMaybe description={description} listingTitle={richTitle} />
                <SectionDetailsMaybe publicData={publicData} customConfig={customConfig} currentStock={currentStock}/>
                <SectionFeaturesMaybe
                  extendedDataKey="amenities"
                  options={amenityOptions}
                  publicData={publicData}
                />
                <SectionRulesMaybe publicData={publicData} />
                <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                />
                {reviews.length > 0 && (
                  <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                )}
                <Modal
                  id="EditListingWizard.payoutModal"
                  isOpen={this.state.verificationModal}
                  onClose={() =>
                    this.setState({
                      verificationModal: false,
                    })
                  }
                  onManageDisableScrolling={onManageDisableScrolling}
                  usePortal
                >
                  <div className={css.modalPayoutDetailsWrapper}>
                    <TwilioVerificationForm
                      className={css.enquiryForm}
                      submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                      listingTitle={title}
                      listing={currentListing}
                      initialValues={{ sellerPhoneNumber: '+1' }}
                      onSubmit={values => { }}
                      handleCodeVerified={handleCodeVerified}
                      onSendVerificationCode={onSendVerificationCode}
                      validationInProgress={validationInProgress}
                      isPhonNumberExit={isPhonNumberExit}
                      codeError={this.state.codeError}
                      isReponseCodeInProgress={this.state.isReponseCodeInProgress}
                      isListingPage={true}
                    />
                  </div>
                </Modal>
                <SectionAuthorMaybe
                  title={title}
                  listing={currentListing}
                  authorDisplayName={authorDisplayName}
                  onContactUser={this.onContactUser}
                  isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                  onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                  sendEnquiryError={sendEnquiryError}
                  sendEnquiryInProgress={sendEnquiryInProgress}
                  onSubmitEnquiry={this.onSubmitEnquiry}
                  currentUser={currentUser}
                  onManageDisableScrolling={onManageDisableScrolling}
                />

                {pageListings.filter(e => e.id.uuid !== currentListing.id.uuid).length > 0 && (
                  <div className={css.Otherlistings}>
                    <div className={css.SectionHeader}>
                      <div>
                        <span className={css.sectionTitle}>
                          <FormattedMessage id="ListingPage.otherListingsHeading" />{' '}
                          <span>({pagination ? pagination.totalItems : pageListings && pageListings.length})</span>
                        </span>{' '}
                      </div>
                      <span className={css.viewAllBtn}>
                        <NamedLink name="ProfilePage" params={{ id: currentAuthor.id.uuid }}>
                          <FormattedMessage id="ListingPage.viewAll" />
                        </NamedLink>
                      </span>
                    </div>
                    <div>
                      {/* className={isMapVariant ? css.listingCardsMapVariant : css.listingCards} */}
                      <Slider {...settings}>
                        {pageListings
                          .filter(e => e.id.uuid !== currentListing.id.uuid)
                          .map(l => (
                            <ListingCard
                              className={css.listingCard}
                              CategorySelected={location.storeType}
                              key={l.id.uuid}
                              listing={l}
                              renderSizes={cardRenderSizes(isMapVariant)}
                              setActiveListing={onActivateListing}
                            />
                          ))}
                      </Slider>
                      {paginationLinks}
                    </div>
                  </div>
                )}
              </div>
              {!isMobile && (
                <div className={css.orderColumnForProductLayout}>
                  <OrderPanel
                    className={css.productOrderPanel}
                    reviews={reviews}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    unitType={unitType}
                    onSubmit={handleOrderSubmit}
                    isStripeConnected={isStripeConnected}
                    title={bookingTitle}
                    author={ensuredAuthor}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onContactUser={this.onContactUser}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    currentUser={currentUser}
                    makeAnOfferButton={makeAnOfferButton}
                    isReportModalOpen={() => {
                      if (!currentUser) {
                        const state = {
                          from: `${location.pathname}${location.search}${location.hash}`,
                        };
                        history.push(
                          createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
                          state
                        );
                      } else {
                        this.setState({
                          isModalOpen: REPORT_USER_MODAL,
                        });
                      }
                    }}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    addToCartAndRemoveFromCart={addToCartAndRemoveFromCart}
                  />
                </div>
              )}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer CategorySelected={storeType} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  customConfig: config.custom,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  customConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    reportUserInProgress,
    currentPageResultIds,
    isStripeConnected,
    pagination
  } = state.ListingPage;

  const { validationInProgress, isPhonNumberExit } = state.EditListingFinalPage;

  const pageListings = getListingsById(state, currentPageResultIds);
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    pagination,
    pageListings,
    isStripeConnected,
    reportUserInProgress,
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    validationInProgress,
    isPhonNumberExit,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onSendOffer: (listingId, message, data) => dispatch(sendOffer(listingId, message, data)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onUpdateProfileData: data => dispatch(updateProfile(data)),
  onSendReportUserEmail: data => dispatch(sendReportUserEmail(data)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onCartItemNotification: emailData => dispatch(cartItemNotification(emailData)),
  onCancelCartItemNotification: batch_id => dispatch(cancelCartItemNotification(batch_id)),
  onSendVerificationCode: verificationData => dispatch(sendPhoneVerificationCode(verificationData)),
  onVerifiedCode: codeData => dispatch(verifiedCode(codeData)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(ListingPageComponent);

export default ListingPage;
