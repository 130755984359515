import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconEnquiry,
  FieldCurrencyInput,
  AvatarMedium,
} from '../../../components';

import css from './MakeAnOfferForm.module.css';
import config from '../../../config';
import { priceData } from '../../../components/OrderPanel/OrderPanel';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { formatMoney } from '../../../util/currency';

const { Money } = sdkTypes;
const MakeAnOfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listing,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
        values,
      } = fieldRenderProps;
      const { price, publicData } = listing.attributes;
      const { formattedPrice, priceTitle } = priceData(price, intl);
      const { size, brand, listingVideoAndImagesUrl } = publicData || {};
      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const messageLabel = intl.formatMessage({
        id: 'MakeAnOfferForm.messageLabel',
      });
      const authorName = intl.formatMessage(
        {
          id: 'MakeAnOfferForm.authorDisplayName',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'EnquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress || (!values && values.offerPrice);

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <h3 className={css.heading}>
            <FormattedMessage id="EnquiryForm.customOffer" />
          </h3>

          <div className={css.inputWrapper}>
            <div className={css.inputPrice}>
              <span className={css.label}>
                {intl.formatMessage({ id: 'EditListingPricingForm.listingPriceePerProduct' })}
              </span>
              <span className={css.text}>{formattedPrice}</span>
            </div>
            <FieldCurrencyInput
              id="offerPrice"
              name="offerPrice"
              className={css.input}
              label={intl.formatMessage({ id: 'EditListingPricingForm.yourPriceePerProduct' })}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
          </div>
          <div className={css.listingDetails}>
            <div className={css.listingImagesSlider}>
              {listingVideoAndImagesUrl?.length &&
              listingVideoAndImagesUrl[0].type === 'video/mp4' ? (
                <video className={css.sliderVideo}>
                  <source
                    src={listingVideoAndImagesUrl?.length && listingVideoAndImagesUrl[0]?.url}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  className={css.sliderVideo}
                  src={listingVideoAndImagesUrl?.length && listingVideoAndImagesUrl[0]?.url}
                />
              )}
            </div>
            <div>
              <h1 className={css.title}>{listingTitle}</h1>
              <div className={css.priceValue} title={priceTitle}>
                <FormattedMessage id="OrderPanel.sizeText" />
                {size} | {brand} | {'$8 shipping'}
              </div>
              <div className={css.listingProfile}>
                <AvatarMedium user={listing.author} className={css.providerAvatar} />
                <div>
                  <span className={css.offerFor}> {messageLabel}</span>
                  <span className={css.offerForAuthorName}> {authorName}</span>
                </div>
              </div>
            </div>
          </div>

          <div className={submitButtonWrapperClassName}>
            <span className={css.smallText}>
              Heads up, an offer isn’t a sale. They’ll have 25 hours to agree, counter, or decline.
            </span>
            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              className={css.submitButton}
            >
              <FormattedMessage id="MakeAnOfferForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

MakeAnOfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

MakeAnOfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const MakeAnOfferForm = compose(injectIntl)(MakeAnOfferFormComponent);

MakeAnOfferForm.displayName = 'MakeAnOfferForm';

export default MakeAnOfferForm;
