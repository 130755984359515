import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconEnquiry,
  FieldCurrencyInput,
  AvatarMedium,
  Button,
} from '../../../components';

import css from './TwilioVerificationForm.module.css';
import config from '../../../config';
import { priceData } from '../../../components/OrderPanel/OrderPanel';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { formatMoney } from '../../../util/currency';

const { Money } = sdkTypes;
const TwilioVerificationFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        values,
        onSendVerificationCode,
        handleCodeVerified,
        validationInProgress,
        isPhonNumberExit,
        codeError,
        isReponseCodeInProgress,
        isListingPage,
      } = fieldRenderProps;
      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = !values.phoneNumberOtp || !values.sellerPhoneNumber;

      const handleSendVerification = async () => {
      await onSendVerificationCode({ phoneNumber: values.sellerPhoneNumber});
      };

      const resendCode = (
        <span
          className={css.resedText}
          onClick={() => values.sellerPhoneNumber && handleSendVerification()}
        >
          <FormattedMessage id="EditListing.resendCode" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <h3 className={css.heading}>
            <FormattedMessage id="EditListing.PhoneVerification" />
          </h3>

          <div className={css.inputWrapper}>
            <FieldTextInput
              id={`sellerPhoneNumber`}
              name="sellerPhoneNumber"
              className={classNames(css.field, isPhonNumberExit && css.errorField)}
              type="text"
              autoComplete="shipping phoneNumber"
              label={intl.formatMessage({
                id: 'EditListingVerification.recipientPhoneNumberLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'ShippingDetails.recipientPhoneNumberPlaceholder',
              })}
            />
          </div>
          {isPhonNumberExit && (
            <p className={css.inputError}>
              <FormattedMessage id="EditListingVerification.errorText" />
            </p>
          )}
          <Button
            inProgress={validationInProgress}
            type="button"
            disabled={values.sellerPhoneNumber === '+1'}
            onClick={handleSendVerification}
            className={css.sendCodeBtn}
          >
            <FormattedMessage id="EditListingVerification.sendCode" />
          </Button>

          <div className={css.inputWrapper} style={{ marginTop: '15px' }}>
            <FieldTextInput
              id={`phoneNumberOtp`}
              name="phoneNumberOtp"
              className={classNames(css.field, codeError && css.errorField)}
              type="text"
              label={intl.formatMessage({ id: 'EditListingVerification.phoneNumberOtpLabel' })}
              placeholder={'--------'}
            />
          </div>
          {codeError && (
            <p className={css.inputError}>
              <FormattedMessage id="EditListingVerification.codeErrorText" />
            </p>
          )}
          <p className={css.resendCodeText}>
            <FormattedMessage id="EditListingVerification.resendText" values={{ resendCode }} />
          </p>
          <div className={submitButtonWrapperClassName}>
            <PrimaryButton
              type="button"
              inProgress={isReponseCodeInProgress}
              disabled={submitDisabled}
              className={css.submitButton}
              onClick={() => {
                handleCodeVerified(values.phoneNumberOtp, values.sellerPhoneNumber);
              }}
            >
              {isListingPage ? (
                <FormattedMessage id="EditListingVerification.submitButton" />
              ) : (
                <FormattedMessage id="EditListingVerification.submitButtonText" />
              )}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

TwilioVerificationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

TwilioVerificationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  inProgress: bool,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TwilioVerificationForm = compose(injectIntl)(TwilioVerificationFormComponent);

TwilioVerificationForm.displayName = 'TwilioVerificationForm';

export default TwilioVerificationForm;
