import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';

import { propTypes } from '../../../util/types';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import {
  AspectRatioWrapper,
  Button,
  IconClose,
  IconArrowHead,
  ResponsiveImage,
  IconCollection,
} from '../../../components';

// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import './image-gallery.css';

import css from './ListingImageGallery.module.css';
import Slider from 'react-slick';

const IMAGE_GALLERY_OPTIONS = {
  showPlayButton: false,
  disableThumbnailScroll: true,
};
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="46"
        viewBox="0 0 26 46"
        fill="none"
      >
        <path
          d="M21.0232 22.7045L1.67351 3.42001C1.48443 3.2328 1.33502 3.00972 1.23413 2.76399C1.13324 2.51827 1.08292 2.25489 1.08615 1.98946C1.08938 1.72402 1.1461 1.46193 1.25295 1.21872C1.3598 0.975494 1.5146 0.756088 1.70818 0.573494C2.10414 0.19939 2.63107 -0.00629437 3.17699 0.000148634C3.7229 0.00659163 4.24478 0.224651 4.63172 0.607994L25.4114 21.3186C25.5994 21.5044 25.7482 21.7257 25.8493 21.9694C25.9504 22.2132 26.0016 22.4746 26 22.7383C25.9984 23.002 25.9439 23.2627 25.8399 23.5052C25.7359 23.7477 25.5843 23.9672 25.394 24.1507L3.52239 45.4134C3.13085 45.7897 2.60781 46 2.06351 46C1.51922 46 0.99618 45.7897 0.604634 45.4134C0.413318 45.2285 0.261228 45.0072 0.157371 44.7627C0.0535134 44.5183 -7.62527e-08 44.2555 -8.78549e-08 43.9901C-9.94571e-08 43.7247 0.0535134 43.462 0.157371 43.2175C0.261228 42.973 0.413318 42.7518 0.604634 42.5669L21.0232 22.7045Z"
          fill="#3065A1"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <svg
        style={{
          transform: 'rotate(178deg)',
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="46"
        viewBox="0 0 26 46"
        fill="none"
      >
        <path
          d="M21.0232 22.7045L1.67351 3.42001C1.48443 3.2328 1.33502 3.00972 1.23413 2.76399C1.13324 2.51827 1.08292 2.25489 1.08615 1.98946C1.08938 1.72402 1.1461 1.46193 1.25295 1.21872C1.3598 0.975494 1.5146 0.756088 1.70818 0.573494C2.10414 0.19939 2.63107 -0.00629437 3.17699 0.000148634C3.7229 0.00659163 4.24478 0.224651 4.63172 0.607994L25.4114 21.3186C25.5994 21.5044 25.7482 21.7257 25.8493 21.9694C25.9504 22.2132 26.0016 22.4746 26 22.7383C25.9984 23.002 25.9439 23.2627 25.8399 23.5052C25.7359 23.7477 25.5843 23.9672 25.394 24.1507L3.52239 45.4134C3.13085 45.7897 2.60781 46 2.06351 46C1.51922 46 0.99618 45.7897 0.604634 45.4134C0.413318 45.2285 0.261228 45.0072 0.157371 44.7627C0.0535134 44.5183 -7.62527e-08 44.2555 -8.78549e-08 43.9901C-9.94571e-08 43.7247 0.0535134 43.462 0.157371 43.2175C0.261228 42.973 0.413318 42.7518 0.604634 42.5669L21.0232 22.7045Z"
          fill="#3065A1"
        />
      </svg>
    </div>
  );
}
const ListingImageGallery = props => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const {
    intl,
    rootClassName,
    className,
    images,
    imageVariants,
    thumbnailVariants,
    handleFavouriteItems,
    indexValue,
    listing,
    handleSocialShare,
  } = props;
  const { listingVideoAndImagesUrl } = listing.attributes.publicData || {};
  const thumbVariants = thumbnailVariants || imageVariants;
  const items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbnail: img.attributes?.variants?.[thumbVariants[0]],
      image: img,
    };
  });

  const renderItem = item => {
    return (
      <AspectRatioWrapper
        width={1}
        height={1}
        className={isFullscreen ? css.itemWrapperFullscreen : css.itemWrapper}
      >
        <div className={css.itemCentering}>
          <ResponsiveImage
            rootClassName={css.item}
            image={item.image}
            alt={item.alt}
            variants={imageVariants}
          />
        </div>
      </AspectRatioWrapper>
    );
  };

  const renderItems =
    listingVideoAndImagesUrl?.length &&
    listingVideoAndImagesUrl
      // ?.filter(e => e.fileName !== 'tag')
      .map((val, index) => {     
        return (
          <div key={index}>
            {['image/gif', 'webp'].includes(val.type) ? (
              <img src={val.url} alt="Your GIF" />
            ) : (
              <video className={css.sliderVideo} controls loop autoPlay>
                <source src={val.url} type="video/mp4" />
              </video>
            )}
          </div>
        );
      });
  const renderThumbInner = item => {
    return (
      <div>
        {listingVideoAndImagesUrl?.length ? (
          listingVideoAndImagesUrl
            // ?.filter(e => e.fileName !== 'tag')
            .map((val, index) => {
              return (
                <div key={index}>
                  {['image/gif', 'webp'].includes(val.type) ? (
                    <img src={val.url} alt="Your GIF" />
                  ) : (
                    <video width="118" height="118" controls loop autoPlay>
                      <source src={val.url} type="video/mp4" />
                    </video>
                  )}
                </div>
              );
            })
        ) : (
          <ResponsiveImage
            rootClassName={css.thumb}
            image={item.image}
            alt={item.thumbAlt}
            variants={thumbVariants}
          />
        )}
      </div>
    );
  };

  const onScreenChange = isFull => {
    setIsFullscreen(isFull);
  };

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button className={css.navLeft} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="left" size="big" />
        </div>
      </button>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <button className={css.navRight} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="right" size="big" />
        </div>
      </button>
    );
  };
  const renderFullscreenButton = (onClick, isFullscreen) => {
    return isFullscreen ? (
      <Button
        onClick={onClick}
        rootClassName={css.close}
        title={intl.formatMessage({ id: 'ListingImageGallery.closeModalTitle' })}
      >
        <span className={css.closeText}>
          <FormattedMessage id="ListingImageGallery.closeModal" />
        </span>
        <IconClose rootClassName={css.closeIcon} />
      </Button>
    ) : (
      <div className={css.imagesButtons}>
        {indexValue > -1 ? (
          <span
            onClick={() => {
              handleFavouriteItems();
            }}
          >
            <IconCollection icon="like" />
          </span>
        ) : (
          <span
            onClick={() => {
              handleFavouriteItems();
            }}
          >
            <IconCollection icon="dislike" />
          </span>
        )}{' '}
        <span
          onClick={() => {
            handleSocialShare();
          }}
          className={css.openFullscreen}
        >
          <IconCollection icon="share" />
          <FormattedMessage id="ListingImageGallery.share" />
        </span>
        {/* <button className={css.openFullscreen} onClick={onClick}>
        <FormattedMessage
          id="ListingImageGallery.viewImagesButton"
          values={{ count: images.length }}
        />
      </button> */}
      </div>
    );
  };

  if (items.length === 0 && listingVideoAndImagesUrl.length === 0) {
    const classes = classNames(rootClassName || css.noImage, className);
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  const classes = classNames(rootClassName || css.root, className);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: function(i) {
      return (
        <div>
          {listingVideoAndImagesUrl?.length &&
            listingVideoAndImagesUrl
              // ?.filter(e => e.fileName !== 'tag')
              .map(
                (val, index) =>
                  i === index && (
                    <div key={index}>
                      {['image/gif', 'webp'].includes(val.type) ? (
                        <img src={val.url} alt="Your GIF" />
                      ) : (
                        <video className={css.sliderVideoThumbnail} controls loop autoPlay>
                          <source src={val.url} type="video/mp4" />
                        </video>
                      )}
                    </div>
                  )
              )}
        </div>
      );
    },

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const storeType = listing.attributes.publicData?.listingStore;

  return (
    <>
      {listingVideoAndImagesUrl?.length ? (
        <div
          className={classNames(
            css.listingImagesSlider,
            storeType == 'sneakers'
              ? css.sneakerBorder
              : storeType == 'streetwear'
              ? css.streetwearBorder
              : storeType == 'vintage'
              ? css.vintageBorder
              : null
          )}
        >
          {renderFullscreenButton()}
          <Slider {...settings}>{renderItems}</Slider>
        </div>
      ) : (
        <ReactImageGallery
          additionalClass={classes}
          items={items}
          renderItem={renderItem}
          showNav={false}
          renderThumbInner={renderThumbInner}
          onScreenChange={onScreenChange}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          renderFullscreenButton={renderFullscreenButton}
          {...IMAGE_GALLERY_OPTIONS}
        />
      )}
    </>
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
  thumbnailVariants: null,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,
  imageVariants: arrayOf(string).isRequired,
  thumbnailVariants: arrayOf(string),

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
