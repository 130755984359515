import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconEnquiry,
  FieldRadioButton,
  Button,
} from '../../../components';

import css from './ReportUserForm.module.css';
import { reportingReasons } from '../../../config/marketplace-custom-config';
const DESCRIPTION_MAX_LENGTH = 1000;
const ReportUserFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
        onClose,
      } = fieldRenderProps;

      const reportingDescriptionLabel = intl.formatMessage(
        {
          id: 'ReportUserForm.reportingDescriptionLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'EnquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const maxDescriptionLengthMessage = intl.formatMessage(
        { id: 'EditListingDetailsForm.maxLength' },
        {
          maxLength: DESCRIPTION_MAX_LENGTH,
        }
      );
      const maxLength100Message = validators.maxLength(
        maxDescriptionLengthMessage,
        DESCRIPTION_MAX_LENGTH
      );

      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          {/* <IconEnquiry className={css.icon} /> */}
          {/* <p className={css.heading}>
            <FormattedMessage id="ReportUserForm.heading" />
          </p>
          <p>
            <FormattedMessage id="ReportUserForm.reportText" />
          </p>
          <p>
            <FormattedMessage id="ReportUserForm.reportFor" />
          </p> */}
          <p className={css.heading}>
            <FormattedMessage id="ReportUserForm.reportingLabel" />
          </p>
          {reportingReasons.map(st => {
            return (
              <FieldRadioButton
                id={st.key}
                name="reportIssue"
                value={st.label}
                key={st.key}
                label={st.label}
              />
            );
          })}
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="reportingDescription"
            id={formId ? `${formId}.reportingDescription` : 'reportingDescription'}
            label={reportingDescriptionLabel}
            // placeholder={messagePlaceholder}
            maxLength={DESCRIPTION_MAX_LENGTH}
            validate={validators.composeValidators(messageRequired, maxLength100Message)}
          />
          <div className={submitButtonWrapperClassName}>
            {/* {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="EnquiryForm.sendEnquiryError" />
              </p>
            ) : null} */}
            <div className={css.buttonsGroup}>
              <Button onClick={() => onClose()} className={css.closeBtn}>
                <FormattedMessage id="ReportUserForm.submitButtonClose" />
              </Button>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="ReportUserForm.submitButtonText" />
              </PrimaryButton>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

ReportUserFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

ReportUserFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ReportUserForm = compose(injectIntl)(ReportUserFormComponent);

ReportUserForm.displayName = 'ReportUserForm';

export default ReportUserForm;
